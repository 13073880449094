import { FC, useCallback, useContext, useMemo } from "react";
import { Settings } from "react-slick";
import { BannersContext, FeedContext, useSiteConfig } from "@finbackoffice/site-core";
import {
    HomePageSectionsEnum,
    ILayoutsHomePageSection,
    ISiteConfigLayouts,
} from "@finbackoffice/fe-core";
import ScrollComponent from "components/base/scroll/ScrollComponent";
import BannersSlider from "components/base/banners-slider/BannersSlider";
import Footer from "components/footer/Footer";
import styles from "./home.module.sass";
import HomeSlots from "./slots/HomeSlots";
import HomeTournamentItem from "./tournament/HomeTournamentItem";
import HomeLiveCasino from "./live-casino/HomeLiveCasino";
import HomeLiveCasinoTables from "./live-casino-tables/HomeLiveCasinoTables";
import HomeFAQ from "./faq/HomeFAQ";

export interface IHomeTournamentData {
    id: number;
    sportSlug: string;
    categoryId: number;
}

const Home: FC = () => {
    const homePageConfig = useSiteConfig<ISiteConfigLayouts>("layouts").homePage;
    const { banners } = useContext(BannersContext);
    const { prematchTopLeagues, hasPrematchSports } = useContext(FeedContext);
    const hasTopLeagues = prematchTopLeagues.length > 0;
    const ready = hasPrematchSports && hasTopLeagues;

    const bannerSettings: Settings = useMemo(
        () => ({
            slidesToScroll: 1,
        }),
        [],
    );

    const renderSection = useCallback(
        (section: ILayoutsHomePageSection, index: number) => {
            if (section.enable) {
                switch (section.name) {
                    case HomePageSectionsEnum.Banners:
                        return (
                            <BannersSlider
                                key={index}
                                banners={banners}
                                sliderSettings={bannerSettings}
                                wrapperClassName={styles.homeBannerContainer}
                                imgProps={{
                                    width: 1312,
                                    height: 300,
                                }}
                            />
                        );
                    case HomePageSectionsEnum.Slots:
                        if (section.tag) {
                            return <HomeSlots key={index} tag={section.tag} />;
                        }
                        break;
                    case HomePageSectionsEnum.LiveCasino:
                        return <HomeLiveCasino key={index} tag={section.tag} />;
                    case HomePageSectionsEnum.LiveCasinoTables:
                        return <HomeLiveCasinoTables key={index} tag={section.tag} />;
                    case HomePageSectionsEnum.Sport:
                        if (section.top_leagues_index && ready) {
                            return section.top_leagues_index.map((leagueIndex) => {
                                const tournament = prematchTopLeagues[leagueIndex - 1];
                                return tournament ? (
                                    <HomeTournamentItem
                                        key={tournament.id}
                                        tournament={tournament}
                                    />
                                ) : null;
                            });
                        }
                        break;
                    default:
                        return null;
                }
            }
            return null;
        },
        [bannerSettings, banners, prematchTopLeagues, ready],
    );

    return (
        <ScrollComponent showScrollBar>
            <div className={styles.middleAreaHomePage}>
                {homePageConfig.widgets.map(renderSection)}
            </div>
            {homePageConfig.enableFAQ && <HomeFAQ />}
            <Footer />
            {/* <div className={styles.homePageItem}>
                        <div className={styles.header}>
                            <span className={styles.headerSport}>
                                <Svg
                                loader
                                    src="/common/sport-icons/1.svg"
                                    wrapper="span"
                                    className="svg-sport-icon"
                                />
                                Soccer
                            </span>
                            <ActiveLink
                                href={`/${RouteNames.SPORT}/${RouteNames.PREMATCH}`}
                                activeClassName={styles.active}
                                includes={`/${RouteNames.SPORT}/${RouteNames.PREMATCH}`}>
                                <a>Top Events</a>
                            </ActiveLink>
                            <nav className={styles.carouselBtns}>
                                <li className={classnames(styles.arrowLeft, styles.disabled)} />
                                <li className={styles.arrowRight} />
                            </nav>
                        </div>

                        <div className={styles.homeGames}>
                            <div>
                                live soccer
                                <div className={styles.homeGameItem}>
                                    <div className={styles.homeGameItemHeader}>
                                        <div>
                                            <Svg
                                                src="/common/regions/30.svg"
                                                wrapper="span"
                                                className="svg-region-icon"
                                            />
                                            Germany / Bundesliga
                                        </div>
                                        <span className={styles.liveGameLabel}>Live</span>
                                    </div>
                                    <div className={classnames(styles.soccer, styles.liveSoccer)}>
                                        <div className={styles.soccerTeam}>
                                            <TeamIcon sportId={1} teamId={22} />
                                            <span>Borussia Dortmund</span>
                                        </div>
                                        <div className={styles.soccerScore}>
                                            <span>
                                                <span>0</span>:<span>1</span>
                                            </span>
                                            Halftime
                                        </div>
                                        <div className={styles.soccerTeam}>
                                            <TeamIcon sportId={1} teamId={31} />
                                            <span>Eintracht Francfurt</span>
                                        </div>
                                    </div>
                                    <div className={styles.homeGameItemMarket}>outcome</div>
                                </div>
                                prematch soccer
                                <div className={styles.homeGameItem}>
                                    <div className={styles.homeGameItemHeader}>
                                        <div>
                                            <Svg
                                                src="/common/regions/30.svg"
                                                wrapper="span"
                                                className="svg-region-icon"
                                            />
                                            Germany / Bundesliga
                                        </div>
                                    </div>
                                    <div className={styles.soccer}>
                                        <div className={styles.soccerTeam}>
                                            <TeamIcon sportId={1} teamId={22} />
                                            <span>Borussia Dortmund</span>
                                        </div>
                                        <div className={styles.soccerScore}>
                                            <span>22:08.22</span>
                                            23:30
                                        </div>
                                        <div className={styles.soccerTeam}>
                                            <TeamIcon sportId={1} teamId={31} />
                                            <span>Eintracht Francfurt</span>
                                        </div>
                                    </div>
                                    <div className={styles.homeGameItemMarket}>outcome</div>
                                </div>
                                <div className={styles.homeGameItem}>
                                    <div className={styles.homeGameItemHeader}>
                                        <div>
                                            <Svg
                                                src="/common/regions/30.svg"
                                                wrapper="span"
                                                className="svg-region-icon"
                                            />
                                            Germany / Bundesliga
                                        </div>
                                    </div>
                                    <div className={styles.soccer}>
                                        <div className={styles.soccerTeam}>
                                            <TeamIcon sportId={1} teamId={22} />
                                            <span>Borussia Dortmund</span>
                                        </div>
                                        <div className={styles.soccerScore}>
                                            <span>22:08.22</span>
                                            23:30
                                        </div>
                                        <div className={styles.soccerTeam}>
                                            <TeamIcon sportId={1} teamId={31} />
                                            <span>Eintracht Francfurt</span>
                                        </div>
                                    </div>
                                    <div className={styles.homeGameItemMarket}>outcome</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.homePageItem}>
                        <div className={styles.header}>
                            <span className={styles.headerSport}>
                                <Svg
                                    src="/common/sport-icons/2.svg"
                                    wrapper="span"
                                    className="svg-sport-icon"
                                />
                                Basketball
                            </span>
                            <ActiveLink
                                href={`/${RouteNames.SPORT}/${RouteNames.PREMATCH}`}
                                activeClassName={styles.active}
                                includes={`/${RouteNames.SPORT}/${RouteNames.PREMATCH}`}>
                                <a>Top Events</a>
                            </ActiveLink>
                            <nav className={styles.carouselBtns}>
                                <li className={classnames(styles.arrowLeft, styles.disabled)} />
                                <li className={styles.arrowRight} />
                            </nav>
                        </div>
                        <div className={styles.homeGames}>
                            <div>
                                live basketball
                                <div className={styles.homeGameItem}>
                                    <div className={styles.homeGameItemHeader}>
                                        <div>
                                            <Svg
                                                src="/common/regions/26.svg"
                                                wrapper="span"
                                                className="svg-region-icon"
                                            />
                                            USA / NBA
                                        </div>
                                        <span className={styles.liveGameLabel}>Live</span>
                                    </div>
                                    <div className={styles.game}>
                                        <div className={styles.gameHeader}>
                                            <div>Set 2</div>
                                            <span>1</span>
                                            <span className={styles.check}>2</span>
                                            <span>T</span>
                                        </div>
                                        <div className={styles.team}>
                                            <TeamIcon sportId={2} teamId={448391} />
                                            <i className={classnames(styles.inning, styles.active)} />
                                            <span className={styles.teamName}>
                                                Marikina Shoemasters
                                            </span>
                                            <span className={classnames(styles.score, styles.check)}>
                                                26
                                            </span>
                                            <span className={styles.score}>10</span>
                                            <span className={styles.total}>
                                                <span>46</span>
                                            </span>
                                        </div>
                                        <div className={styles.team}>
                                            <TeamIcon sportId={2} teamId={448765} />
                                            <i className={styles.inning} />
                                            <span className={styles.teamName}>
                                                San Juan Knights
                                            </span>
                                            <span className={styles.score}>10</span>
                                            <span className={styles.score}>16</span>
                                            <span className={styles.total}>
                                                <span>26</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className={styles.homeGameItemMarket}>outcome</div>
                                </div>
                                prematch basketball
                                <div className={styles.homeGameItem}>
                                    <div className={styles.homeGameItemHeader}>
                                        <div>
                                            <Svg
                                                src="/common/regions/26.svg"
                                                wrapper="span"
                                                className="svg-region-icon"
                                            />
                                            USA / NBA
                                        </div>
                                    </div>
                                    <div className={styles.game}>
                                        <div className={styles.gameHeader}>
                                            <div>22.08.22 23:00</div>
                                        </div>
                                        <div className={styles.team}>
                                            <TeamIcon sportId={2} teamId={448391} />
                                            <span className={styles.teamName}>
                                                Marikina Shoemasters
                                            </span>
                                        </div>
                                        <div className={styles.team}>
                                            <TeamIcon sportId={2} teamId={448765} />
                                            <span className={styles.teamName}>
                                                San Juan Knights
                                            </span>
                                        </div>
                                    </div>
                                    <div className={styles.homeGameItemMarket}>outcome</div>
                                </div>
                            </div>
                        </div>
                    </div> */}
        </ScrollComponent>
    );
};

export default Home;
