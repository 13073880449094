import Link from "next/link";
import { FC, useContext, useMemo } from "react";
import { Settings } from "react-slick";
import { TranslationScopes } from "@finbackoffice/enums";
import { useRuntimeConfig, useTranslation } from "@finbackoffice/site-core";
import FadeInAnimation from "components/base/fade-in/FadeInAnimation";
import Loading from "components/base/loading/Loading";
import SlickSlider from "components/base/slick-slider/SlickSlider";
import Translate from "components/base/translate/Translate";
import { CasinoGameItem } from "components/pages/casino/game-item/CasinoGameItem";
import { Svg } from "components/base/svg/Svg";
import { RouteNames } from "utils/constants";
import { HomeSlotsContext } from "../../HomeSlots";
import styles from "./compact.module.sass";

const Compact: FC = () => {
    const { t } = useTranslation(TranslationScopes.Casino);
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const { casinoGamesState, tag } = useContext(HomeSlotsContext);

    const sliderSettings: Settings = useMemo(
        () => ({
            dots: false,
            infinite: false,
            variableWidth: true,
            speed: 1000,
            slidesToShow: 5,
            slidesToScroll: 2,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1762,
                    settings: {
                        slidesToShow: 4,
                    },
                },
                {
                    breakpoint: 1532,
                    settings: {
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 1084,
                    settings: {
                        slidesToShow: 2,
                    },
                },
            ],
        }),
        [],
    );

    return (
        <div className={styles.homePageItem}>
            <div className={styles.header}>
                <h2 className={styles.headerSport}>
                    <Svg
                        src="/common/desktop/base-icons/slots-icon.svg"
                        wrapper="span"
                        className="svg-icon"
                    />
                    <Translate tid="home_slots_title" namespace={TranslationScopes.Home} />
                </h2>
                <h2>
                    <Translate
                        tid="home_slots_head"
                        namespace={TranslationScopes.Home}
                        replace={{
                            app_name: COMMON_SITE_CONFIGS.appName,
                            tag: tag ? t(`casino_${tag?.toLowerCase()}`) : "",
                        }}
                    />
                </h2>
                <Link href={`/${RouteNames.CASINO}/${RouteNames.SLOTS}`}>
                    <Translate tid="home_show_all" namespace={TranslationScopes.Home} />
                </Link>
            </div>
            {casinoGamesState.loading && <Loading />}
            {casinoGamesState.games.length !== 0 && (
                <FadeInAnimation>
                    <div className={styles.slotsGames}>
                        <div>
                            <SlickSlider settings={sliderSettings}>
                                {casinoGamesState.games.map((game) => (
                                    <CasinoGameItem key={game.id} game={game} />
                                ))}
                            </SlickSlider>
                        </div>
                    </div>
                </FadeInAnimation>
            )}
        </div>
    );
};

export default Compact;
